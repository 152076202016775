const config = {
    "broker": "1",
    "name": "Chinese",
    "registrationBundle": "chinese",
    "defaultCountryName": "China",
    "defaultCountryCode": "CN",
    "fxgeoip_code": "CN",
    languageCode: 'cn',
    languageName:'Chinese',
    spokenLanguageCode:'zh-hans',
    direction:'ltr',
    footerDisclaimer:'740',
    brand: 'iforex'
};
module.exports = config;